* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #212A34;
  font-family: 'Ubuntu', sans-serif;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}